export const getErrorMessages = (respExcp) =>{

    if(!respExcp || !respExcp.exceptionMessage) return 'Unknown error'

    if(!respExcp.exceptionMessage.errors && respExcp.exceptionMessage) return respExcp.exceptionMessage

    if(respExcp.exceptionMessage.errors){
        const firstKey = respExcp.exceptionMessage.errors[Object.keys(respExcp.exceptionMessage.errors)[0]]
        return Array.isArray(firstKey) ? firstKey[0] : firstKey
    }

    return 'Unknown error'
}