import React, { useState } from 'react'
import loaderIcon from '../assets/images/svg/loader2.svg'

export function useElementLoader(size="20") {
  const loader = <img key={Math.random()} style={{height:`${size}px`}} src={loaderIcon}  alt='loader' />
  return [loader]
}

// TODO: Set CSS
export function usePageLoader(defaultState) {
  const [visible, setVisible] = useState(defaultState)

  const showLoader = () => setVisible(true)
  const hideLoader = () => setVisible(false)
  const loader = visible ? <img src={loaderIcon} alt='loader' /> : null

  return [loader, showLoader, hideLoader]
}
