import React from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'

const colorStyles = {
  placeholder: defaultStyles => ({
      ...defaultStyles,
      fontSize: '1em',
  }),
  control: defaultStyles => ({ 
    ...defaultStyles, 
    fontSize: '1.15em',
  })
}

const FormSelect = ({label, required, value, isMulti, ...rest}) => {
  return (
    <Form.Group>
        {label && <Form.Label>{label} {required && <span className="text-danger">*</span>}</Form.Label> }
        <div style={{position:"relative"}}>
            { required &&
            <input 
                className="select-required" 
                type="text" 
                value={isMulti ? (value[0] ?? '') : (value ?? '')} 
                onChange={()=>{}}
                tabIndex={-1}
                required={required}
                z-index="-1"
            />
            }
            <Select value={value} isMulti={isMulti} 
            styles={colorStyles} 
            {...rest}/>
        </div>
    </Form.Group>
  )
}

export default FormSelect
